import React, { useContext } from "react";
import "./Intro.css";
import twitter from "../../img/twitter-01.png";
import LinkedIn from "../../img/linkdin-01.png";
import upwork from "../../img/UPWORK-01.png";
import banner from "../../img/banner.svg";
import { themeContext } from "../../Context";
import { Link } from "react-scroll";
const Intro = () => {

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode; 

  return (
    <div className="md:flex w-full pt-4 md:pt-[4rem]" id="Intro">
      <div className="w-full md:w-1/2">
        <div className="i-name pb-[3rem] md:pb-2">
          <span style={{ color: darkMode ? "white" : "" }}>Hey! We Are</span>
          <span>Zitsol</span>
          <span className="pb-[1rem]">
            Team of Developers with high level of experience in web/Mobile Apps designing
            and development, producting the Quality work. We are Zitsol, We’ve Been Assisting our valued customers with their day-to-day information technology needs. No matter you are a new company or a fully established firm, we can help you in choosing the best Information Technology solutions to meet your business needs. We do not try to be the cheapest, we negotiate and offer the best value for money. We believe this approach allows us to create long-term partnerships with our customers and business associates
          </span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
          <button className="button i-button">Hire us</button>
        </Link>
        {/* social icons */}
        <div className="flex justify-center py-[1rem]">
          <a href="https://www.linkedin.com/company/zitsolinfo/"><img src={LinkedIn} alt="" className="w-[4rem]"/></a>
          <a href="https://twitter.com/Zitsolnet"><img src={twitter} alt="" className="w-[4rem]" /></a>
          <a href="https://www.upwork.com/freelancers/~01ff0f8c90c7b96c8c"><img src={upwork} alt="" className="w-[4rem]"/></a>
           </div>
      </div>
     <div className="w-full md:w-1/2">
       <img src={banner} alt="" className="w-[22rem] aspect-auto float-none md:float-right"/>
     </div>
    </div>
  );
};

export default Intro;
