import React, { useContext } from "react";
import "./Services.css";
import { themeContext } from "../../Context";

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
      <div className="pt-[14rem] md:pt-[1rem]">
           <div className="py-0 md:py-24 text-center awesome">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Our</span>
      <span>Services</span>
    </div>
    <div className="services justify-center mt-20" id="services">
      
      <div class="flex items-center justify-center pt-[44rem] md:pt-0">
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2">
       
        <div class="relative bg-white py-6 px-6 rounded-3xl  my-4 shadow-xl">
            <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-pink-500 left-4 -top-6">
               
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                </svg>
            </div>
            <div class="mt-8">
                <p class="text-xl font-semibold my-2">App Development</p>
                <div class="flex space-x-2 text-gray-400 text-sm">
                   
                <p>An intuitive, mobile-first experience is a powerful way to differentiate your brand and grow a loyal customer base. Zitsol offers a complete set of mobile app development services using native and cross-platform frameworks. We create outstanding mobile experiences on Android and iOS using scalable backend solutions. We continuously innovate and release app updates on a regular cadence to ensure that your mobile apps remain indispensable for your customers and team members.</p>
                </div>
                </div>
        </div>

       
        <div class="relative bg-white py-6 px-6 rounded-3xl my-4 shadow-xl">
            <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-green-500 left-4 -top-6">
               
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
            </div>
            <div class="mt-8">
                <p class="text-xl font-semibold my-2">Web Design</p>
                <div class="flex space-x-2 text-gray-400 text-sm">
                   
                     <p>Custom web development offers options for businesses who seek to provide a flexible, consistent experience for users across platforms. Leveraging new approaches to web development including progressive web apps, we bring front-end, back-end and architecture ability together to deliver on your business need and maximize delivery speed. We provide development, deployment and maintenance on Shopify, WooCommerce, BigCommerce & Joomla also.</p> 
                </div>
            </div>
        </div>

       
        <div class="relative bg-white py-6 px-6 rounded-3xl my-4 shadow-xl">
            <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-blue-500 left-4 -top-6">
               
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                </svg>
            </div>
            <div class="mt-8">
                <p class="text-xl font-semibold my-2">Leading Page</p>
                <div class="flex space-x-2 text-gray-400 text-sm">
                   
                    
                     <p>Able to lead the creation of the user experience or execute it through visual design, we bring expertise in all stages from proof-of-concept prototyping to complete mobile/web solution transformation. Advocates of participatory design, we collaborate with your team on stakeholder interviews, usability testing, task/decision flows, wireframes and clickable prototypes to align with the complex interdependencies presented by modern businesses.</p> 
              </div>
                
            </div>
        </div>

        
        <div class="relative bg-white py-6 px-6 rounded-3xl my-4 shadow-xl">
            <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-yellow-500 left-4 -top-6">
               
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
            </div>
            <div class="mt-8">
                <p class="text-xl font-semibold my-2">Digital Marketing</p>
                <div class="flex space-x-2 text-gray-400 text-sm">
                     <p>Take a step forward and let your product succeed. We’re a digital marketing company that combines expertise from all over the world and helps you revolutionize market trends. Let us help you create solutions for real-life problems. Are you ready to outperform your competition, enhance user-experience, and pave the path of success for you and your clients?</p> 
                </div>
               
            </div>
        </div>
    </div>
</div>
    </div>
      </div>
   
  );
};

export default Services;
