import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profilePic2,
      review:
        "I have been working with Zitsol for two or three years. In that time, I have found them to be reliable, polite, professional and hard working. He always researches new topics thoroughly and goes above and beyond what is expected. They also have a lot of experience in website development, design, and blogging. I highly recommend other website owners to get in touch with Zitsol if they are looking for a professional Website Developer to help them increase Business to their website. You will not be disappointed.",
    },
    {
      img: profilePic1,
      review:
        "Zitsol is great! Easy to work with, communicates very well and is always professional. Their content is top notch and we really appreciate them. Their knowledge of WordPress and technology in general has made a huge difference in our company. Thanks again for everything!"
    },
    
    {
      img: profilePic3,
      review:
      "I had a great experience working with them. We started out by discussing the project. They were very responsive and quick to respond. Well recommended :)"
    },
    {
      img: profilePic4,
      review:
        "Excellent.. Always to love work with Zitsol, Explain every point clearly when i need and also helps to sort out things related to projects.  Easy to work with, communicates very well and is always professional."
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from us...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
